<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>B端数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>机构管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">机构信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.districtId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="机构省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                v-model="queryForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                clearable
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChange('search')"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="机构名称" prop="name">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 机构列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">机构信息列表</span>
        </el-col>
        <el-col :span="15">
          <div class="div_algin_right">
            <el-button type="primary" @click="generateEntryCodesClicked"
              >批量生成参赛码</el-button
            >
            <el-button type="primary" @click="addSchoolInfoClicked()"
              >新增机构+</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="赛区" prop="districtName"></el-table-column>
        <el-table-column label="省市区" prop="provence">
          <template slot-scope="scope">
            <span
              >{{ scope.row.province }}-{{ scope.row.city }}-{{
                scope.row.area
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="机构名称" prop="name">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="批量参赛码" prop="uniqueCode"></el-table-column>
        <el-table-column label="地址" prop="address"></el-table-column>
        <el-table-column label="机构类型" prop="schoolType">
          <template slot-scope="scope">
            <span v-if="scope.row.type + '' === '1'">早教类</span>
            <span v-else-if="scope.row.type + '' === '2'">教培类</span>
            <span v-else-if="scope.row.type + '' === '3'">英语类</span>
            <span v-else>机构类</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="国家" prop="nationality"></el-table-column> -->
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="delecteEditDialog(scope.row)"
              >删除</el-button
            > -->
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="renewalEditClicked(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑信息的dialog -->
    <el-dialog
      title="添加机构信息"
      :visible.sync="editInfoDialogVisible"
      width="60%"
      @close="editInfoDialogClose"
    >
      <el-form
        :model="editInfoForm"
        label-width="100px"
        ref="editInfoFormRef"
        :rules="editInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="机构省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                v-model="editInfoForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChange('addSchool')"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="机构名称" prop="name">
              <el-input
                v-model="editInfoForm.name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="机构类型" prop="schoolType">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.schoolType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_schoolType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="机构地址" prop="schoolAddress">
              <el-input
                v-model="editInfoForm.schoolAddress"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="国家" prop="nationality">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.nationality"
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_countries"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="editInfoForm.remark"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 联系人信息 -->
        <el-card>
          <contact-list
            style="margin-top: 15px"
            :data="editInfoForm.contactList"
            @add="addContact"
            @edit="editContact"
            @remove="removeContact"
          ></contact-list>
          <el-row type="flex" justify="center" style="margin-top: 20px">
            <el-col :span="4">
              <el-button type="primary" style="width: 100%" @click="save"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysInstitutionList, addSysSchool, createSchoolUniqueCode, queryDistrictPage, getAllAreaList } from '@/http/api'
export default {
  data () {
    return {
      dict_district: [],
      hotCountry: { value: 'China', label: '中国' },
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      editInfoForm: {
        districtId: '',
        districtName: '',
        schoolArea: [],
        name: '',
        schoolType: '',
        schoolAddress: '',
        nationality: '',
        // nationalityCode: '',
        remark: ''
      },
      dict_schoolType: this.$userInfo.getDataList(this, 'school_type').then(value => { this.dict_schoolType = value }),
      dict_countries: this.$userInfo.dict_countries(),
      editInfoRules: {
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        schoolType: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
        nationality: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dataList: [],
      areasData: [],
      editInfoDialogVisible: false
    }
  },
  created () {
    this.getDistrictList()
    this.getDataList()
    this.getAllAreaList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取机构列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getSysInstitutionList(this.queryForm).then((res) => {
        console.log('getSysSchoolList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 机构省市区选择
    provincesHandleChange (value) {
      console.log('aaaaaaaa', this.$refs.schoolAreaRef.getCheckedNodes())
      if (this.$refs.schoolAreaRef.getCheckedNodes().length !== 0) {
        if (value === 'addSchool') {
          this.editInfoForm.provinceCode = this.editInfoForm.schoolArea[0]
          this.editInfoForm.cityCode = this.editInfoForm.schoolArea[1]
          this.editInfoForm.areaCode = this.editInfoForm.schoolArea[2]
          const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
          const areaList = deliveryAreaName.split('/')
          this.editInfoForm.province = areaList[0]
          this.editInfoForm.city = areaList[1]
          this.editInfoForm.area = areaList[2]
        } else {
          this.queryForm.provinceCode = this.queryForm.schoolArea[0]
          this.queryForm.cityCode = this.queryForm.schoolArea[1]
          this.queryForm.areaCode = this.queryForm.schoolArea[2]
          const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
          const areaList = deliveryAreaName.split('/')
          this.queryForm.province = areaList[0]
          this.queryForm.city = areaList[1]
          this.queryForm.area = areaList[2]
        }
      }
    },
    // 添加机构点击事件
    addSchoolInfoClicked () {
      console.log('2222')
      var editInfoForm = {
        districtId: '',
        schoolLevel: [],
        districtName: '',
        schoolArea: [],
        name: '',
        schoolType: '',
        schoolAddress: '',
        nationality: '',
        nationalityCode: '',
        remark: ''
      }
      this.$router.push({
        path: '/system_data_Management/system_mechanism_details',
        query: {
          editInfoForm: JSON.stringify(editInfoForm)
        }
      })
      // this.editInfoDialogVisible = true
    },
    // 批量生成参赛码
    generateEntryCodesClicked () {
      createSchoolUniqueCode({}).then((res) => {
        this.$message.success('操作成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改机构信息
    renewalEditClicked (changeData) {
      this.editInfoForm = changeData
      this.editInfoForm.schoolType = this.editInfoForm.schoolType + ''
      this.editInfoForm.schoolArea = [changeData.provinceCode + '', changeData.cityCode + '', changeData.areaCode + '']
      this.$router.push({
        path: '/system_data_Management/system_mechanism_details',
        query: {
          editInfoForm: JSON.stringify(this.editInfoForm)
        }
      })
    },
    // 保存机构信息
    editInfo () {
      addSysSchool(this.editInfoForm).then((res) => {
        this.$message.success('操作成功')
        this.editInfoDialogVisible = false
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    editInfoDialogClose () {
      this.$refs.editInfoFormRef.resetFields()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
